import React from "react"

import { gql } from "@apollo/client"

import { IMAGE_PARTS } from "../../utils/fragments"
import Picture from "../Atoms/Picture"

const BlockImage = ({ block }) => {
  const thumbnailUrl =
    block.image.data?.attributes?.formats?.thumbnail?.url || ""
  const smallImageUrl = block.image.data?.attributes?.formats?.small?.url || ""
  const mediumImageUrl =
    block.image.data?.attributes?.formats?.medium?.url || ""
  const largeImageUrl = block.image.data?.attributes?.formats?.large?.url || ""
  return (
    <Picture
      thumbnail={thumbnailUrl}
      small={smallImageUrl}
      medium={mediumImageUrl}
      large={largeImageUrl}
      className="block mx-auto"
      alt={
        block.image.data?.attributes?.alternativeText ||
        block.image.data?.attributes?.formats?.thumbnail.name
      }
    />
  )
}

export default BlockImage

export const BLOCK_IMAGE_PARTS = gql`
  fragment BLOCK_IMAGE_PARTS on ComponentSharedImage {
    image {
      ...IMAGE_PARTS
    }
  }

  ${IMAGE_PARTS}
`
