import React, { useEffect, useState } from "react"

import IconChevronDown from "./../../icons/chevron-down-light.svg"
import IconChevronUp from "./../../icons/chevron-up-light.svg"
import ProfitRow from "./ProfitRow"

const WineProfitCalculator = ({
  product,
  buttonText,
  buttonCssClasses,
  buttonActiveCssClasses,
  iconCssClasses,
  onButtonClick,
  isShown,
}) => {
  return (
    <>
      <button
        onClick={() => onButtonClick()}
        className={isShown ? buttonActiveCssClasses : buttonCssClasses}
      >
        {buttonText}{" "}
        <img
          src={isShown ? IconChevronUp : IconChevronDown}
          className={iconCssClasses}
          alt={buttonText}
          width="16"
        />
      </button>
      {isShown && (
        <section className="order-2 w-[200%] ml-[-45%] sm:w-full sm:ml-0 my-4 py-10 grid grid-cols-5 gap-1">
          <div className="text-primary text-center font-small font-bold">
            Annos
          </div>
          <div className="text-primary text-center font-small font-bold">
            Listahinta
          </div>
          <div className="text-primary text-center font-small font-bold">
            Myynti €
          </div>
          <div className="text-primary text-center font-small font-bold">
            Kate €
          </div>
          <div className="text-primary text-center font-small font-bold">
            Kate %
          </div>
          <ProfitRow
            product={product}
            name="12 cl"
            cartItemKey="priceGlass12"
            units={12}
            isPackage={false}
            isShown={!product.attributes.soldOnlyInPackage}
          ></ProfitRow>
          <ProfitRow
            product={product}
            name="16 cl"
            cartItemKey="priceGlass16"
            units={16}
            isPackage={false}
            isShown={!product.attributes.soldOnlyInPackage}
          ></ProfitRow>
          <ProfitRow
            product={product}
            name="pl"
            cartItemKey="pricePackage"
            units={1}
            isPackage={true}
            isShown={!product.attributes.soldOnlyInGlass}
          ></ProfitRow>
        </section>
      )}
    </>
  )
}
export default WineProfitCalculator
