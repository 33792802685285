import { Link } from "gatsby"
import React from "react"

import { gql, useQuery } from "@apollo/client"

import IconNext from "../../icons/circle-chevron-left-solid-blue.svg"
import IconPlus from "../../icons/circle-plus-solid.svg"
import Layout from "../Layout"

const OrderReceived = () => {
  const GET_CTA_BUTTONS = gql`
    query getCTAButtons {
      settings {
        data {
          attributes {
            ShopButtonLabel
            ShopButtonUrl
          }
        }
      }
    }
  `
  const { loading, data } = useQuery(GET_CTA_BUTTONS)

  return (
    <Layout>
      <div className="h-screen grid v-full place-items-center text-white">
        {loading || !data ? (
          <div className="loader-animation loader-animation__alternate"></div>
        ) : (
          <div className="flex flex-col flex-wrap xs:w-full sm:w-5/6 justify-center content-center">
            <h1 className="flex text-2xl text-white font-bold justify-center">
              Valikoima vahvistettu!
            </h1>
            <div className="flex flex-row gap-10">
              <Link
                className="flex justify-center items-center py-2 pl-10 pr-2 mt-20 text-xl font-bold text-primary bg-white rounded-full"
                to={data.settings.data.attributes.ShopButtonUrl}
              >
                <span className="inline-block mr-6">
                  {data.settings.data.attributes.ShopButtonLabel}
                </span>
                <div className="inline-block align-bottom mr-2">
                  <img src={IconNext} width="40" className="block rotate-180" />
                </div>
              </Link>
              <Link
                className="flex justify-center items-center py-2 pl-10 pr-4 mt-20 text-xl font-bold text-primary bg-white rounded-full"
                to="/app/profile"
              >
                <span className="inline-block mr-6">Uusi valikoima</span>
                <div className="inline-block align-bottom ml-2">
                  <img src={IconPlus} width="40" className="block rotate-180" />
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default OrderReceived
