import React from "react"

const Picture = ({ thumbnail, small, medium, large, alt, fallback }) => {
  return (
    <picture className="block mx-auto mt-10">
      {large !== "" && (
        <source srcSet={large + " 1024w"} media="(min-width:1024px)"></source>
      )}
      {medium !== "" && (
        <source srcSet={medium + " 650w"} media="(min-width:650px)"></source>
      )}
      {small !== "" && (
        <source srcSet={small + " 420w"} media="(min-width:420px)"></source>
      )}
      <img
        src={thumbnail ? thumbnail : fallback || ""}
        alt={alt ? alt : "Kuvaa ei saatavilla"}
        className="block mx-auto mt-10"
      />
    </picture>
  )
}

export default Picture
