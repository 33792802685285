import { navigate } from "gatsby"
import React from "react"
import { useForm } from "react-hook-form"

import { gql, useMutation } from "@apollo/client"

import { isLoggedIn, setUser } from "../../utils/auth"
import Layout from "../Layout"
import Logo from "./../../img/logo-neg.png"

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm()

  const LOGIN = gql`
    mutation LOGIN($identifier: String!, $password: String!) {
      login(input: { identifier: $identifier, password: $password }) {
        jwt
        user {
          id
          username
          email
        }
      }
    }
  `
  const [doLogin, { loading, error: backendErrors }] = useMutation(LOGIN)

  const onSubmit = (values) => {
    doLogin({
      variables: values,
      onCompleted: (data) => {
        setUser(data.login)
        navigate(`/app/customer-selection`)
      },
    }).catch((error) => {
      // Just chew the error
    })
  }

  if (isLoggedIn()) {
    navigate(`/app/customer-selection`)
    return null
  }

  return (
    <Layout>
      {loading ? (
        <div className="loader-animation"></div>
      ) : (
        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="p-8 flex items-center flex-col"
        >
          <img src={Logo} className="mx-auto" alt="Sinebrychoff logo"></img>
          {backendErrors && (
            <p className="text-sm text-red-900 mb-2">{backendErrors.message}</p>
          )}
          <div className="mb-4">
            <input
              className={`w-full text-primary font-light max-w-md appearance-none border-[#E9E9E9] py-4 px-6 ${
                formErrors.identifier ? "bg-red-200" : ""
              }`}
              type="text"
              placeholder="User ID"
              {...register("identifier", { required: true })}
            />
          </div>
          <div className="mb-6">
            <input
              className={`w-full text-primary font-light max-w-md appearance-none border-[#E9E9E9] py-4 px-6 ${
                formErrors.password ? "bg-red-200" : ""
              }`}
              type="password"
              placeholder="Password"
              {...register("password", { required: true })}
            />
          </div>
          <div className="flex items-center justify-between">
            <input
              className="inline-block mx-auto bg-koff-blue rounded-full py-4 px-10 font-bold text-white"
              type="submit"
              value="Log In"
            />
          </div>
        </form>
      )}
    </Layout>
  )
}

export default Login
