import { gql } from "@apollo/client"

export const IMAGE_PARTS = gql`
  fragment IMAGE_PARTS on UploadFileEntityResponse {
    data {
      attributes {
        name
        formats
      }
    }
  }
`
