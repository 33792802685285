import { Link } from "gatsby"
import React, { useContext } from "react"

import { PageContext } from "../Context/PageContextProvider"
import Layout from "../Layout"
import IconWineGlassEmpty from "./../../icons/wine-glass-empty-light-white.svg"

const CustomerSelection = () => {
  return (
    <PageContext.Provider value={{ pageType: "dark" }}>
      <Layout>
        <div className="h-screen grid v-full place-items-center">
          <Link
            className="inline-block mx-auto w-110 py-39 bg-koff-blue-alternate font-bold rounded-full text-white text-center"
            to="/app/select-profile"
          >
            <img
              src={IconWineGlassEmpty}
              width="16"
              className="block mx-auto pb-2"
            />
            Uusi asiakas
          </Link>
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default CustomerSelection
