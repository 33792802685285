import React from "react"

import { gql } from "@apollo/client"

const BlockTitle = ({ block }) => {
  return (
    <h3 className="font-bold text-center text-primary text-3xl mt-6">
      {block.content}
    </h3>
  )
}

export default BlockTitle

export const BLOCK_TITLE_PARTS = gql`
  fragment BLOCK_TITLE_PARTS on ComponentSharedTitle {
    content
  }
`
