import { Link } from "gatsby"
import { navigate } from "gatsby"
import React from "react"

import Layout from "../Layout"

const ErrorOffline = () => {
  return (
    <Layout>
      <div className="h-screen grid v-full place-items-center text-white">
        <div className="grid place-items-center text-white">
          <h1 className=" text-2xl text-white font-bold mb-4">
            Virhe yhteydessä
          </h1>
          <p className="mb-20">
            Tarkista internetyhteytesi ja kokeile uudestaan.
          </p>
          <Link
            className={
              "inline-block mx-auto bg-koff-blue rounded-full py-2 px-4 font-bold text-white"
            }
            onClick={() => {
              navigate(-1)
            }}
            to=""
          >
            <span className="inline-block self-center mr-2 ml-2">Takaisin</span>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorOffline
