import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useCart } from "react-use-cart"

import { PageContext } from "../Context/PageContextProvider"
import Layout from "../Layout"
import IconChecked from "./../../icons/circle-check-solid.svg"
import IconPlus from "./../../icons/circle-plus-solid.svg"
import FallbackImage from "./../../img/kuvaa_ei_saatavilla.jpg"
import ProductCard from "./../Organisms/ProductCard"

const Actions = ({ id, onSelect }) => {
  return (
    <div onClick={(e) => onSelect(e, id)} className="absolute right-12 top-12">
      <div className="rounded-full bg-white inline-block align-bottom hover:cursor-pointer">
        <img src={IconPlus} width="32" className="block rotate-45 opacity-30" />
      </div>
    </div>
  )
}

const Cart = () => {
  const { items, removeItem } = useCart()

  const [filteredProductList, setFilteredProductList] = useState(items)
  const [wineFilter, setWineFilter] = useState("")

  const filterByWineType = (filteredData) => {
    if (wineFilter !== "") {
      return filteredData.filter(
        (item) =>
          item.attributes.productCategory.data.attributes.name === wineFilter
      )
    }
    return filteredData
  }
  const updateWineFilter = (filter) => {
    setWineFilter(filter.toLowerCase())
  }
  const itemCount = (type) => {
    return items.filter(
      (item) => item.attributes.productCategory.data.attributes.name === type
    ).length
  }

  const handleDelete = (event, id) => {
    const curEl = event.currentTarget
    const elToAnimate = curEl.closest(".wine-card")
    // Using web animations web API, we only give the resulting "state" of animation and let the browser determine "where to start".
    const deletingAnimation = elToAnimate.animate(
      {
        transform: "scaleY(0)",
        opacity: 0,
      },
      500
    )
    deletingAnimation.onfinish = () => removeItem(id)
  }
  const allItemsCount = () => {
    return items.length
  }
  const filterClasses =
    "inline-block text-center rounded-full px-6 py-2 my-2 mr-4 hover:cursor-pointer hover:bg-primary hover:text-koff-light-gold"

  useEffect(() => {
    let filteredData = filterByWineType(items)
    setFilteredProductList(filteredData)
  }, [wineFilter, items])
  return (
    <PageContext.Provider value={{ pageType: "light" }}>
      <Layout>
        <div className="grid place-items-center bg-white text-secondary pt-60">
          <div className="p-8 grid place-items-center">
            <h1 className="font-bold text-4xl my-10 text-center text-primary">
              Vahvista valikoima
            </h1>
            <div className="grid grid-cols-2 sm:grid-cols-3 sm:grid-cols-auto md:grid-cols-none md:grid-cols-max md:grid-flow-col md:auto-cols-max gap-2">
              <div
                className={`${filterClasses} ${
                  wineFilter === ""
                    ? "text-koff-light-gold bg-koff-blue"
                    : "text-primary bg-koff-light-gold"
                }`}
                onClick={() => updateWineFilter("")}
              >
                Suositusvalikoima ({allItemsCount()})
              </div>
              <div
                className={`${filterClasses} ${
                  wineFilter === "punaviini"
                    ? "text-koff-light-gold bg-koff-blue"
                    : "text-primary bg-koff-light-gold"
                }`}
                onClick={() => updateWineFilter("punaviini")}
              >
                Punaviinit ({itemCount("punaviini")})
              </div>
              <div
                className={`${filterClasses} ${
                  wineFilter === "valkoviini"
                    ? "text-koff-light-gold bg-koff-blue"
                    : "text-primary bg-koff-light-gold"
                }`}
                onClick={() => updateWineFilter("valkoviini")}
              >
                Valkoviinit ({itemCount("valkoviini")})
              </div>
              <div
                className={`${filterClasses} ${
                  wineFilter === "rosé viini"
                    ? "text-koff-light-gold bg-koff-blue"
                    : "text-primary bg-koff-light-gold"
                }`}
                onClick={() => updateWineFilter("rosé viini")}
              >
                Rosé viinit ({itemCount("rosé viini")})
              </div>
              <div
                className={`${filterClasses} ${
                  wineFilter === "kuohuviini"
                    ? "text-koff-light-gold bg-koff-blue"
                    : "text-primary bg-koff-light-gold"
                }`}
                onClick={() => updateWineFilter("kuohuviini")}
              >
                Kuohuviinit ({itemCount("kuohuviini")})
              </div>
            </div>
            <section className="my-6 mb-8">
              {filteredProductList &&
                filteredProductList.map((product, idx) => {
                  return (
                    <ProductCard
                      key={product.id}
                      product={product}
                      fallback={FallbackImage}
                      actions={
                        <Actions
                          id={product.id}
                          onSelect={(e) => handleDelete(e, product.id)}
                        />
                      }
                    />
                  )
                })}
            </section>
            <section className="flex justify-center w-full p-9 space-x-4 sticky bottom-0 bg-white/75">
              <Link
                to={"/app/search-form"}
                className="flex justify-center py-4 px-14 text-xl font-bold bg-primary text-white rounded-full relative"
              >
                <span className="inline-block self-center mr-10 ml-2">
                  Lisää viini{" "}
                </span>
                <div className="inline-block self-center -mr-12">
                  <span className="koff-icon-fill-white-42">
                    <img
                      src={IconPlus}
                      width="40"
                      className="relative block mx-auto z-10"
                    />
                  </span>
                </div>
              </Link>
              <Link
                className={
                  "flex justify-center text-xl font-bold bg-primary text-white rounded-full relative px-14 " +
                  (items.length === 0 ? "opacity-30" : "")
                }
                onClick={(event) => {
                  event.preventDefault()
                  if (items.length !== 0) {
                    navigate("/app/checkout")
                  }
                }}
                to=""
              >
                <span className="inline-block self-center mr-10 ml-2">
                  Jatka näillä tiedoilla{" "}
                </span>
                <div className="inline-block self-center -mr-12">
                  <span className="koff-icon-fill-white-42">
                    <img
                      src={IconChecked}
                      width="40"
                      className="relative block mx-auto z-10"
                    />
                  </span>
                </div>
              </Link>
            </section>
          </div>
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default Cart
