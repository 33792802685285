import { Link } from "gatsby"
import React, { useState } from "react"
import { useCart } from "react-use-cart"

import Layout from "../Layout"
import IconChecked from "./../../icons/circle-check-solid.svg"
import { PageContext } from "./../Context/PageContextProvider"

const SelectProfile = () => {
  const { setCartMetadata, clearCartMetadata, emptyCart } = useCart()
  const [slugType, setSlugType] = useState(null)

  const setProfile = (slug) => {
    setSlugType(slug)
    emptyCart()
    clearCartMetadata()
    setCartMetadata({
      profileSlug: slug,
      profile: null,
    })
  }
  const profileBalloonStyles =
    "inline-block mx-20 w-113 py-48 my-10 grow-0 bg-koff-blue-alternate rounded-full font-bold text-white text-center "
  const selectedBalloonStyles =
    "outline outline-2 outline-offset-2 outline-white"
  const unSelectedBalloonStyles = "opacity-50"
  return (
    <PageContext.Provider value={{ pageType: "dark" }}>
      <Layout>
        <div className="h-screen grid v-full place-items-center text-white relative">
          <div className="grid xs:w-full sm:w-full md:w-full lg:w-5/6 xl:w-2/3 2xl:w-3/5 place-content-center">
            <div className="flex justify-center flex-wrap md:w-full">
              <div
                onClick={() => setProfile("pub")}
                className={
                  profileBalloonStyles +
                  (slugType === "pub" || slugType === "pub-food"
                    ? selectedBalloonStyles
                    : slugType === null ? "" : unSelectedBalloonStyles )
                }
              >
                Pub
              </div>
              <div
                onClick={() => setProfile("bistro")}
                className={
                  profileBalloonStyles +
                  (slugType === "bistro"
                    ? selectedBalloonStyles
                    : slugType === null ? "" : unSelectedBalloonStyles)
                }
              >
                Dining
              </div>
              <div
                onClick={() => setProfile("terrace")}
                className={
                  profileBalloonStyles +
                  (slugType === "terrace"
                    ? selectedBalloonStyles
                    : slugType === null ? "" : unSelectedBalloonStyles)
                }
              >
                Terassi
              </div>
              <div
                onClick={() => setProfile("club")}
                className={
                  profileBalloonStyles +
                  (slugType === "club"
                    ? selectedBalloonStyles
                    : slugType === null ? "" : unSelectedBalloonStyles)
                }
              >
                Juhliminen
              </div>
              <div
                onClick={() => setProfile("modern")}
                className={
                  profileBalloonStyles +
                  (slugType === "modern"
                    ? selectedBalloonStyles
                    : slugType === null ? "" : unSelectedBalloonStyles)
                }
              >
                Moderni juoma
              </div>
              <div className="mt-40">
                {(slugType === "pub" || slugType === "pub-food") && (
                  <>
                    <h1 className="col-span-2 text-center text-2xl mb-10">
                      Ruokatarjoilu?
                    </h1>
                    <div
                      onClick={() => setProfile("pub-food")}
                      className={
                        "inline-block mx-10 w-56 py-20 grow-0 bg-koff-green rounded-full font-bold text-white text-center ml-80  " +
                        (slugType === "pub-food" ? selectedBalloonStyles : "")
                      }
                    >
                      Kyllä
                    </div>
                    <div
                      onClick={() => setProfile("pub")}
                      className={
                        "inline-block mx-10 w-56 py-20 grow-0 bg-koff-dark-red rounded-full font-bold text-white text-center mr-80 " +
                        (slugType === "pub" ? selectedBalloonStyles : "")
                      }
                    >
                      Ei
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="grid w-64 place-content-center absolute bottom-10">
            <Link
              to="/app/profile"
              className={
                "px-14 py-5 text-xl font-bold text-primary bg-white rounded-full relative pr-10 " +
                (slugType === null ? "opacity-30" : "")
              }
              disabled={slugType !== null ? true : false}
            >
              <span className="inline-block py-2 mr-6">Jatka </span>
              <img
                src={IconChecked}
                width="40"
                className="inline-block mx-auto ml-4 -mt-1 -mr-8"
              />
            </Link>
          </div>
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default SelectProfile
