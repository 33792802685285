import { navigate } from "gatsby"
import React from "react"
import NumberFormat from "react-number-format"
import { useCart } from "react-use-cart"

import { gql, useQuery } from "@apollo/client"

import { IMAGE_PARTS } from "../../utils/fragments"
import DiscountProductStripe from "../Atoms/DiscountProductStripe"
import { PageContext } from "../Context/PageContextProvider"
import Layout from "../Layout"
import BlockImage, { BLOCK_IMAGE_PARTS } from "../Molecules/BlockImage"
import BlockRichText, {
  BLOCK_RICH_TEXT_PARTS,
} from "../Molecules/BlockRichText"
import BlockTitle, { BLOCK_TITLE_PARTS } from "../Molecules/BlockTitle"
import ChevronIcon from "./../../icons/circle-chevron-left-solid-blue.svg"
import FallbackImage from "./../../img/kuvaa_ei_saatavilla.jpg"
import ConditionalWrapper from "./../Atoms/ConditionalWrapper"
import Picture from "./../Atoms/Picture"

const Profile = () => {
  const { metadata, setCartMetadata, addItem } = useCart()

  const GET_CLIENT_PROFILE = gql`
    query GET_CLIENT_PROFILE($slug: String!) {
      clientProfiles(filters: { slug: { eq: $slug } }) {
        data {
          id
          attributes {
            name
            blocks {
              __typename
              ... on ComponentSharedImage {
                ...BLOCK_IMAGE_PARTS
              }
              ... on ComponentSharedTitle {
                ...BLOCK_TITLE_PARTS
              }
              ... on ComponentSharedText {
                ...BLOCK_RICH_TEXT_PARTS
              }
            }
            products(
              sort: "sortOrder:asc"
              pagination: { start: 0, limit: -1 }
            ) {
              data {
                id
                attributes {
                  name
                  country
                  alcContents
                  acidContents
                  description
                  aroma
                  taste
                  pairing
                  grape
                  packageSize
                  price
                  discountPrice
                  sugarContents
                  soldOnlyInGlass
                  soldOnlyInPackage
                  sortOrder
                  priceCategory {
                    data {
                      attributes {
                        value
                      }
                    }
                  }
                  image {
                    ...IMAGE_PARTS
                  }
                  productCategory {
                    data {
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ${IMAGE_PARTS}
    ${BLOCK_IMAGE_PARTS}
    ${BLOCK_RICH_TEXT_PARTS}
    ${BLOCK_TITLE_PARTS}
  `

  const { loading, data } = useQuery(GET_CLIENT_PROFILE, {
    variables: { slug: metadata.profileSlug },
    onCompleted: (data) => {
      setCartMetadata({
        ...metadata,
        profile: data.clientProfiles.data[0],
      })
    },
  })
  // NO PROFILE: navigate back to selecting profile instead of crashing
  if (!metadata.profileSlug) navigate("/app/select-profile")

  const approveProducts = () => {
    data.clientProfiles.data[0].attributes.products.data.map((product) => {
      return addItem({
        ...product,
        price: 0,
      })
    })
    navigate("/app/cart")
  }

  return (
    <PageContext.Provider value={{ pageType: "light" }}>
      <Layout>
        <div className="grid place-items-center bg-white text-secondary pt-90">
          {loading || !data ? (
            <div className="loader-animation loader-animation__alternate"></div>
          ) : (
            <div className="p-8 grid place-items-center">
              <h1 className="font-bold text-4xl text-center text-primary">
                {data.clientProfiles.data[0].attributes.name}
              </h1>
              <section className="my-6 mb-8 prose">
                {data.clientProfiles.data[0] &&
                  data.clientProfiles.data[0].attributes.blocks.map(
                    (block, i) => {
                      let key = i.toString()
                      if (block) {
                        switch (block.__typename) {
                          case "ComponentSharedImage":
                            return (
                              <BlockImage block={block} key={key}></BlockImage>
                            )
                          case "ComponentSharedTitle":
                            return (
                              <BlockTitle block={block} key={key}></BlockTitle>
                            )
                          case "ComponentSharedText":
                            return (
                              <BlockRichText
                                block={block}
                                key={key}
                              ></BlockRichText>
                            )
                          default:
                            return null
                        }
                      }
                      return null
                    }
                  )}
              </section>
              <section className="my-6">
                <h2 className="font-bold text-4xl text-center text-primary mb-6">
                  Valikoiman viinit
                </h2>
                {data.clientProfiles.data[0] &&
                  data.clientProfiles.data[0].attributes.products.data.map(
                    (product) => {
                      return (
                        <div
                          className="wine-card relative overflow-hidden gap-4 border-2 border-[#E9E9E9] p-4 my-2"
                          key={product.id}
                        >
                          <div>
                            {product.attributes.discountPrice && (
                              <DiscountProductStripe></DiscountProductStripe>
                            )}
                            <Picture
                              thumbnail={
                                product.attributes.image?.data?.attributes
                                  ?.formats?.thumbnail?.url || ""
                              }
                              small={
                                product.attributes.image?.data?.attributes
                                  ?.formats?.small?.url || ""
                              }
                              medium={
                                product.attributes.image?.data?.attributes
                                  ?.formats?.medium?.url || ""
                              }
                              large={
                                product.attributes.image?.data?.attributes
                                  ?.formats?.large?.url || ""
                              }
                              alt={
                                product.attributes.image?.data?.attributes
                                  ?.alternativeText ||
                                product.attributes.image?.data?.attributes
                                  ?.formats?.thumbnail.name
                              }
                              fallback={FallbackImage}
                              className="block"
                            />
                          </div>
                          <div className="text-primary flex flex-col justify-center">
                            <h4 className="font-bold text-2xl">
                              {product.attributes.name}{" "}
                              <NumberFormat
                                value={product.attributes.alcContents}
                                suffix="%"
                                isNumericString={true}
                                decimalSeparator=","
                                decimalScale={2}
                                displayType="text"
                                defaultValue=""
                              />
                            </h4>
                            <h5 className="text-xl mb-4">
                              {product.attributes?.description}{" "}
                              <span className="lowercase">
                                {
                                  product.attributes.productCategory.data
                                    ?.attributes?.name
                                }
                              </span>
                              , {product.attributes.country}
                            </h5>
                            <strong className="font-bold inline-block mr-2">
                              {product.attributes.discountPrice && (
                                <NumberFormat
                                  value={product.attributes.discountPrice}
                                  suffix="€ "
                                  isNumericString={true}
                                  decimalSeparator=","
                                  decimalScale={2}
                                  displayType="text"
                                  defaultValue=""
                                />
                              )}
                              <ConditionalWrapper
                                condition={product.attributes.discountPrice}
                                wrapper={(children) => <s>{children}</s>}
                              >
                                <NumberFormat
                                  value={product.attributes.price}
                                  suffix="€"
                                  isNumericString={true}
                                  decimalSeparator=","
                                  decimalScale={2}
                                  displayType="text"
                                  defaultValue=""
                                />
                              </ConditionalWrapper>
                            </strong>{" "}
                            <NumberFormat
                              value={product.attributes.packageSize}
                              isNumericString={true}
                              suffix=" l"
                              decimalSeparator=","
                              decimalScale={2}
                              displayType="text"
                              defaultValue=""
                            />
                          </div>
                        </div>
                      )
                    }
                  )}
              </section>
              <div
                className="flex justify-center py-4 text-xl font-bold bg-primary text-white rounded-full relative px-12 hover:cursor-pointer"
                onClick={() => approveProducts()}
              >
                <span className="inline-block self-center mr-10 ml-2">
                  Näyttää hyvältä, aloitetaan näillä{" "}
                </span>
                <div className="inline-block self-center -mr-9">
                  <span className="koff-icon-fill-white-42">
                    <img
                      src={ChevronIcon}
                      width="40"
                      className="block rotate-180 z-10"
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default Profile
