import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useCart } from "react-use-cart"

import { gql, useMutation } from "@apollo/client"

import { getCurrentUser } from "../../utils/auth"
import { PageContext } from "../Context/PageContextProvider"
import Layout from "../Layout"
import IconChecked from "./../../icons/circle-check-solid.svg"

const Checkout = () => {
  const { metadata, items, clearCartMetadata, emptyCart } = useCart()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { selectedProductInformation: "checked" },
  })

  const CREATE_ORDER = gql`
    mutation CREATE_ORDER($data: OrderInput!) {
      createOrder(data: $data) {
        data {
          id
        }
      }
    }
  `
  const [createOrder, { loading, error: backendErrors }] =
    useMutation(CREATE_ORDER)

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const onSubmit = (values) => {
    const products = items.map((item) => {
      return {
        product: parseInt(item.id),
        pricePackage: parseFloat(item?.pricePackage || 0),
        priceGlass12: parseFloat(item?.priceGlass12 || 0),
        priceGlass16: parseFloat(item?.priceGlass16 || 0),
      }
    })

    const orderInput = {
      client: {
        name: values.clientName,
        customerNumber: values.clientCustomerNumber,
        email: values.clientEmail,
        contactPerson: values.clientContact,
      },
      lineItems: products,
      dateCompleted: new Date().toISOString(),
      notes: values.notes,
      selectedList: values.selectedList ? "layout1" : null,
      selectedProductInformation: values.selectedProductInformation
        ? "yes"
        : null,
      clientProfile: metadata.profile ? parseInt(metadata.profile.id) : null,
      usersPermissionsUser: parseInt(getCurrentUser().id),
      publishedAt: new Date().toISOString(),
    }

    createOrder({
      variables: {
        data: orderInput,
      },
      onCompleted: (data) => {
        setIsFormSubmitting(true)
        clearCartMetadata()
        emptyCart()
        navigate("/app/order-received")
      },
    }).catch((error) => {
      // Munch munch
    })
  }

  if (items.length < 1 && isFormSubmitting === false)
    navigate("/app/select-profile")

  const inputClass = "text-primary font-bold block mt-3 mb-7 py-3 px-4 w-180"
  const errorClass = "text-sm text-error"

  return (
    <PageContext.Provider value={{ pageType: "dark" }}>
      <Layout>
        <>
          {loading ? (
            <div className="loader-animation"></div>
          ) : (
            <div className="h-screen grid v-full place-items-center text-white mt-[100px]">
              {backendErrors && (
                <p className="text-sm text-red-900 mb-2">
                  {backendErrors.message}
                </p>
              )}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="grid place-items-center"
              >
                <section>
                  <h1 className="text-white text-3xl font-bold text-center mb-10">
                    Tilaustiedot
                  </h1>
                  <input
                    placeholder="Ravintolan nimi"
                    className={inputClass}
                    {...register("clientName", {
                      required: "Ole hyvä ja anna nimi",
                    })}
                  />
                  {errors.clientName && (
                    <p className={errorClass}>{errors.clientName.message}</p>
                  )}
                  <input
                    placeholder="Asiakasnumero"
                    className={inputClass}
                    {...register("clientCustomerNumber", {
                      required: "Ole hyvä ja anna asiakasnumero",
                    })}
                  />
                  {errors.clientCustomerNumber && (
                    <p className={errorClass}>
                      {errors.clientCustomerNumber.message}
                    </p>
                  )}
                  <input
                    placeholder="Sähköposti"
                    className={inputClass}
                    {...register("clientEmail", {
                      required: "Ole hyvä ja anna sähköpostiosoite",
                    })}
                  />
                  {errors.clientEmail && (
                    <p className={errorClass}>{errors.clientEmail.message}</p>
                  )}
                  <input
                    placeholder="Asiakkaan yhteyshenkilö"
                    className={inputClass}
                    {...register("clientContact")}
                  />
                  <textarea
                    rows="4"
                    placeholder="Lisätietoja"
                    className={inputClass}
                    {...register("notes")}
                  />
                </section>

                <section className="mt-12">
                  <h1 className="text-white text-3xl font-bold text-center">
                    Lisäpalvelut
                  </h1>
                  <input
                    id="tulostettava-viinilista"
                    className="absolute invisible rounded-button-checkbox"
                    type="checkbox"
                    {...register("selectedList")}
                  />
                  <label
                    htmlFor="tulostettava-viinilista"
                    className="inline-block py-2 px-6 mt-6 mx-6 text-primary bg-white rounded-full font-bold"
                  >
                    Tulostettava viinilista
                  </label>

                  <input
                    id="tuotetietokortit"
                    className="absolute invisible rounded-button-checkbox"
                    type="checkbox"
                    value="yes"
                    {...register("selectedProductInformation")}
                  />
                  <label
                    htmlFor="tuotetietokortit"
                    className="inline-block py-2 px-6 mt-6 text-primary bg-white rounded-full font-bold"
                  >
                    Tuotetietokortit
                  </label>
                </section>
                <button
                  className="flex justify-center items-center py-2 pl-10 pr-4 mt-20 text-xl font-bold text-primary bg-white rounded-full"
                  type="submit"
                  value="» Submit"
                >
                  <span className="inline-block mr-6">Vahvista</span>
                  <div className="inline-block align-bottom ml-2">
                    <img src={IconChecked} width="40" className="block" />
                  </div>
                </button>
              </form>
            </div>
          )}
        </>
      </Layout>
    </PageContext.Provider>
  )
}

export default Checkout
