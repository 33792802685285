import React from "react"

/* Render x amount of Euro icons wrapped inside a <span> */

const PriceCategoryIcon = ({ amount }) => {
  return [...Array(amount)].map((el, i) => (
    <span
      key={i}
      className="relative bg-koff-gold font-bold text-white text-sm text-center rounded-full w-[23px] h-[23px]"
    >
      <span className="absolute top-1 left-[6px]">€</span>
    </span>
  ))
}

export default PriceCategoryIcon
