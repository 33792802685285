import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import PriceCategoryIcon from "../Atoms/PriceCategoryIcon"
import { PageContext } from "../Context/PageContextProvider"
import Layout from "../Layout"
import IconChecked from "./../../icons/circle-check-solid-koff-light-blue.svg"
import { defaultSearchParams } from "./SearchProducts"

const getLastUsedParams = () => {
  return window.localStorage.gatsbyLastUsedParams
    ? JSON.parse(window.localStorage.gatsbyLastUsedParams)
    : {}
}

const setLastUsedParams = (params) => {
  window.localStorage.gatsbyLastUsedParams = JSON.stringify(params)
}

const SearchForm = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors: formErrors },
  } = useForm()

  const onSubmit = (values) => {
    setLastUsed(values)
    navigate(
      `/app/search-products?clientProfile=${values.clientProfile}&priceCategory=${values.priceCategory}&productCategory=${values.productCategory}`
    )
  }

  const [lastUsed, setLastUsed] = useState(
    Object.assign({}, defaultSearchParams, getLastUsedParams())
  )

  const hasAllRequiredCriterion = () => {
    const paramsFromLS = getLastUsedParams()
    let hasAll = true
    if (
      paramsFromLS.clientProfile === undefined ||
      paramsFromLS.productCategory === undefined
    )
      hasAll = false
    if (
      paramsFromLS.clientProfile === null ||
      paramsFromLS.productCategory === null
    )
      hasAll = false
    return hasAll
  }

  const isChecked = (slug, id) => {
    return lastUsed[slug] === id
  }

  const onChange = () => {
    setLastUsedParams(getValues())
    setLastUsed(getValues())
  }
  const roundedLabelStyles =
    "inline-block flex justify-center items-center justify-between px-8 mt-4 mx-3 text-primary text-xl bg-white rounded-full gap-1 h-24"
  const sectionHeaderStyles = "font-bold text-koff-gold text-center"
  const roundedLabelWrapperStyles =
    "flex flex-wrap align-items-center justify-center"

  return (
    <PageContext.Provider value={{ pageType: "dark" }}>
      <Layout>
        <div className="h-screen grid place-items-center">
          <div className="grid place-items-center text-white">
            <form
              onSubmit={handleSubmit(onSubmit)}
              onChange={onChange}
              className="grid place-items-center"
            >
              <section className="my-12 w-4/5">
                <h2 className={sectionHeaderStyles}>Sopivuus</h2>
                <div className={roundedLabelWrapperStyles}>
                  {[
                    {
                      slug: "pub-extra",
                      name: "Pub",
                    },
                    {
                      slug: "pub-food-extra",
                      name: "Dining",
                    },
                    {
                      slug: "club-extra",
                      name: "Juhliminen",
                    },
                    {
                      slug: "terrace-extra",
                      name: "Terassi",
                    },
                    {
                      slug: "bistro-extra",
                      name: "Moderni ruoka",
                    },
                    {
                      slug: "",
                      name: "Kaikki",
                    },
                  ].map((profile) => {
                    return (
                      <span key={profile.slug} className="flex">
                        <input
                          id={"profile-" + profile.slug}
                          className="absolute invisible rounded-button-checkbox"
                          type="radio"
                          checked={isChecked("clientProfile", profile.slug)}
                          name="clientProfile"
                          value={profile.slug}
                          {...register("clientProfile")}
                        />
                        <label
                          htmlFor={"profile-" + profile.slug}
                          className={roundedLabelStyles}
                        >
                          {profile.name}
                        </label>
                      </span>
                    )
                  })}
                </div>
              </section>

              <section className="my-12 w-4/5">
                <h2 className={sectionHeaderStyles}>Viinityyppi</h2>
                <div className={roundedLabelWrapperStyles}>
                  {[
                    {
                      slug: "valkoviini",
                      name: "Valkoviini",
                    },
                    {
                      slug: "punaviini",
                      name: "Punaviini",
                    },
                    {
                      slug: "kuohuviini",
                      name: "Kuohuviini",
                    },
                    {
                      slug: "rose-viini",
                      name: "Rosé viini",
                    },
                    {
                      slug: "",
                      name: "Kaikki",
                    },
                  ].map((category) => {
                    return (
                      <span key={category.slug}>
                        <input
                          id={"category-" + category.slug}
                          className="absolute invisible rounded-button-checkbox"
                          type="radio"
                          checked={isChecked("productCategory", category.slug)}
                          name="productCategory"
                          value={category.slug}
                          {...register("productCategory")}
                        />
                        <label
                          htmlFor={"category-" + category.slug}
                          className={roundedLabelStyles}
                        >
                          {category.name}
                        </label>
                      </span>
                    )
                  })}
                </div>
              </section>

              <section className="my-12 w-4/5">
                <h2 className={sectionHeaderStyles}>Hintaluokka</h2>
                <div className={roundedLabelWrapperStyles}>
                  {[
                    {
                      slug: "a",
                      name: <PriceCategoryIcon amount={1}></PriceCategoryIcon>,
                    },
                    {
                      slug: "b",
                      name: <PriceCategoryIcon amount={2}></PriceCategoryIcon>,
                    },
                    {
                      slug: "c",
                      name: <PriceCategoryIcon amount={3}></PriceCategoryIcon>,
                    },
                    {
                      slug: "",
                      name: "Kaikki",
                    },
                  ].map((priceCategory) => {
                    return (
                      <span key={priceCategory.slug}>
                        <input
                          id={"priceCategory-" + priceCategory.slug}
                          className="absolute invisible rounded-button-checkbox"
                          type="radio"
                          checked={isChecked(
                            "priceCategory",
                            priceCategory.slug
                          )}
                          name="priceCategory"
                          value={priceCategory.slug}
                          {...register("priceCategory")}
                        />
                        <label
                          htmlFor={"priceCategory-" + priceCategory.slug}
                          className={roundedLabelStyles}
                        >
                          {priceCategory.name}
                        </label>
                      </span>
                    )
                  })}
                </div>
              </section>
              <section className="mt-12">
                <button
                  className={
                    "inline-block px-12 py-2 text-l font-bold bg-koff-blue text-white rounded-full relative pr-[75px] " +
                    (!hasAllRequiredCriterion() ? "opacity-30" : "")
                  }
                  disabled={!hasAllRequiredCriterion() ? true : false}
                  type="submit"
                  value="» Submit"
                >
                  <span className="inline-block py-6">Näytä viinit</span>
                  <div className="absolute right-4 top-3">
                    <span className="koff-icon-fill-white-42">
                      <img
                        src={IconChecked}
                        width="40"
                        className="block relative z-10"
                      />
                    </span>
                  </div>
                </button>
              </section>
            </form>
          </div>
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default SearchForm
