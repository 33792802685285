import React from "react"

const ManuallyAddedProductStripe = () => {
  return (
    <div className="text-white font-bold bg-koff-blue-alternate absolute top-17 -left-22 -rotate-45 px-20">
      Lisätty tuote
    </div>
  )
}

export default ManuallyAddedProductStripe
