import React from "react"

const DiscountProductStripe = () => {
  return (
    <div className="text-back font-bold bg-koff-yellow absolute top-8 -left-14 -rotate-45 px-20">
      Ale!
    </div>
  )
}

export default DiscountProductStripe
