import React, { useEffect, useState } from "react"
import NumberFormat from "react-number-format"
import { useCart } from "react-use-cart"

/**
 * @param name Name of the row
 * @param product Product data from cart
 * @param cartItemKey Key where the value is saved in cart
 * @param isShown Should be shown or not
 * @param units Cl units
 * @param isPackage Is this row for package pricing
 * @returns {JSX.Element}
 * @constructor
 */
const ProfitRow = ({
  name,
  product,
  cartItemKey,
  isShown,
  units,
  isPackage,
}) => {
  const { updateItem } = useCart()

  const packageSize = product.attributes.packageSize
  const listPrice = product.attributes.price
  // Price by units or by package (if package)
  const unitPrice = isPackage
    ? listPrice
    : (listPrice / (packageSize * 100)) * units
  // State for entered selling price
  const [value, setValue] = useState(product[cartItemKey] || null)
  // Stage for calculated margins
  const [margins, setMargins] = useState({
    margin: null,
    marginPercents: null,
  })

  // Calculate margins
  const calculateMargin = () => {
    const margin = value > 0 ? value - unitPrice : 0
    const marginPercents = value > 0 ? (margin / value) * 100 : 0

    setMargins({
      margin: margin > 0 ? margin : 0,
      marginPercents: marginPercents > 0 ? marginPercents : 0,
    })
  }

  // Render empty NumberFormats as dash, we are expecting the real value as third param
  const renderText = (str, props, value) => {
    if (value <= 0) {
      str = "-"
    }
    return <span {...props}>{str}</span>
  }

  // Update when the value is changed
  useEffect(() => {
    updateItem(product.id, {
      [cartItemKey]: value,
    })
    calculateMargin()
  }, [value])

  return (
    <>
      {isShown && (
        <>
          <div className="text-primary text-center font-small font-bold">
            {name}
          </div>
          {/*List price*/}
          <div className="text-primary text-center font-small font-bold pt-1 border border-[#E9E9E9]">
            <NumberFormat
              value={unitPrice}
              className="w-full text-center"
              decimalSeparator=","
              decimalScale={2}
              displayType="text"
            />
          </div>
          {/*Sell price*/}
          <div className="text-primary font-small font-bold border border-[#E9E9E9] p-1">
            <NumberFormat
              value={value}
              onValueChange={(values) => setValue(values.floatValue)}
              className="w-full text-center"
              isNumericString={true}
              decimalSeparator=","
              decimalScale={2}
              displayType="input"
              allowNegative={false}
              defaultValue=""
            />
          </div>
          {/*Calculated margin in currency*/}
          <div className="text-primary text-center font-small font-bold border border-[#E9E9E9] p-1">
            <NumberFormat
              value={margins.margin}
              className="w-full text-center"
              decimalSeparator=","
              decimalScale={2}
              displayType="text"
              renderText={(str, props) =>
                renderText(str, props, margins.margin)
              }
            />
          </div>
          {/*Calculated percents*/}
          <div className="text-primary text-center font-small font-bold border border-[#E9E9E9] p-1">
            <NumberFormat
              value={margins.marginPercents}
              className="w-full text-center"
              decimalSeparator=","
              decimalScale={0}
              displayType="text"
              suffix=" %"
              renderText={(str, props) =>
                renderText(str, props, margins.marginPercents)
              }
            />
          </div>
        </>
      )}
    </>
  )
}

export default ProfitRow
