import React, { useState } from "react"
import NumberFormat from "react-number-format"

import ManuallyAddedProductStripe from "../Atoms/ManuallyAddedProductStripe"
import DiscountProductStripe from "../Atoms/DiscountProductStripe"
import Picture from "./../Atoms/Picture"
import PriceCategoryIcon from "./../Atoms/PriceCategoryIcon"
import WineInfo from "./../Molecules/WineInfo"
import WineProfitCalculator from "./../Molecules/WineProfitCalculator"
import ConditionalWrapper from "./../Atoms/ConditionalWrapper"

const ProductCard = ({ product, fallback, actions }) => {
  const [wineInfoComponentState, setWineInfoComponentState] = useState(false)
  const [profitCalculatorComponentState, setProfitCalculatorComponentState] =
    useState(false)

  const changeComponentStates = (component) => {
    if (component === "WineInfo") {
      setWineInfoComponentState((prevState) => !prevState)
      setProfitCalculatorComponentState(false)
    } else {
      setWineInfoComponentState(false)
      setProfitCalculatorComponentState((prevState) => !prevState)
    }
  }
  return (
    <div
      className="wine-card relative overflow-hidden gap-4 border-2 border-[#E9E9E9] p-4 my-2"
      key={product.id}
    >
      <div>
        <div>{actions}</div>
        {product.manuallyAdded && !product.attributes.discountPrice && (
          <ManuallyAddedProductStripe></ManuallyAddedProductStripe>
        )}
        {product.attributes.discountPrice && (
          <DiscountProductStripe></DiscountProductStripe>
        )}
        <Picture
          key={product.id}
          thumbnail={
            product.attributes.image?.data?.attributes?.formats?.thumbnail
              ?.url || ""
          }
          small={
            product.attributes.image?.data?.attributes?.formats?.small?.url ||
            ""
          }
          medium={
            product.attributes.image?.data?.attributes?.formats?.medium?.url ||
            ""
          }
          large={
            product.attributes.image?.data?.attributes?.formats?.large?.url ||
            ""
          }
          alt={
            product.attributes.image?.data?.attributes?.alternativeText ||
            product.attributes.image?.data?.attributes?.formats?.thumbnail.name
          }
          fallback={fallback}
          className="block"
        />
      </div>
      <div>
        <h4 className="font-bold text-primary text-2xl pr-20 mt-10">
          {product.attributes.name}{" "}
          <NumberFormat
            value={product.attributes.alcContents}
            suffix="%"
            isNumericString={true}
            decimalSeparator=","
            decimalScale={2}
            displayType="text"
            defaultValue=""
          />
        </h4>
        <h5 className="text-primary text-xl mb-4">
          {product.attributes?.description}{" "}
          <span className="lowercase">
            {product.attributes.productCategory.data?.attributes?.name}
          </span>
          , {product.attributes.country}
        </h5>
        <p className="text-primary">
          <strong className="font-bold inline-block mr-2">
            {product.attributes.discountPrice &&
              <NumberFormat
                value={product.attributes.discountPrice}
                suffix="€ "
                isNumericString={true}
                decimalSeparator=","
                decimalScale={2}
                displayType="text"
                defaultValue=""
              />
            }
            <ConditionalWrapper
              condition={product.attributes.discountPrice}
              wrapper={children => <s>{children}</s>}
            >
              <NumberFormat
                value={product.attributes.price}
                suffix="€"
                isNumericString={true}
                decimalSeparator=","
                decimalScale={2}
                displayType="text"
                defaultValue=""
              />
            </ConditionalWrapper>
          </strong>{" "}
          <NumberFormat
            value={product.attributes.packageSize}
            suffix=" l"
            isNumericString={true}
            decimalSeparator=","
            decimalScale={2}
            displayType="text"
            defaultValue=""
          />
        </p>
        <p className="pt-6 pb-10 flex justify-start gap-2">
          <PriceCategoryIcon
            key={product.id}
            amount={product.attributes.priceCategory.data?.attributes?.value}
          />
        </p>
        <div className="mt-4 mb-10 flex flex-wrap">
          <WineInfo
            key={`info-${product.id}`}
            onButtonClick={() => changeComponentStates("WineInfo")}
            buttonText="Lisätiedot"
            product={product}
            buttonCssClasses="-order-1 bg-koff-blue text-white px-10 py-6 mr-12"
            buttonActiveCssClasses="bg-koff-light-blue text-white px-10 py-6 mr-12"
            iconCssClasses="inline-block mb-1 ml-2"
            isShown={wineInfoComponentState}
          />
          <WineProfitCalculator
            key={`profit-${product.id}`}
            onButtonClick={() => changeComponentStates("WineProfitCalculator")}
            buttonText="Katelaskuri"
            product={product}
            buttonCssClasses="order-1 bg-koff-blue text-white px-10 py-6 my-2 sm:my-0"
            buttonActiveCssClasses="bg-koff-light-blue text-white px-10 py-6 my-2 sm:my-0"
            iconCssClasses="inline-block mb-1 ml-2"
            isShown={profitCalculatorComponentState}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductCard
