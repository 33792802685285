import dompurify from "dompurify"
import React from "react"

import { gql } from "@apollo/client"

const RichText = ({ block }) => {
  const sanitizer = dompurify.sanitize
  const content = block?.content || ""
  return (
    <div
      className="text-primary"
      dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
    ></div>
  )
}

export default RichText

export const BLOCK_RICH_TEXT_PARTS = gql`
  fragment BLOCK_RICH_TEXT_PARTS on ComponentSharedText {
    content
  }
`
