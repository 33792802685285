import { navigate } from "gatsby"
import { parse } from "query-string"
import React, { useEffect, useState } from "react"
import { useCart } from "react-use-cart"

import { gql, useQuery } from "@apollo/client"
import { useLocation } from "@reach/router"

import { IMAGE_PARTS } from "../../utils/fragments"
import { PageContext } from "../Context/PageContextProvider"
import Layout from "../Layout"
import ProductCard from "../Organisms/ProductCard"
import IconCheckedSelected from "./../../icons/circle-check-solid-koff-green.svg"
import IconChecked from "./../../icons/circle-check-solid.svg"
import IconPlus from "./../../icons/circle-plus-solid.svg"
import FallbackImage from "./../../img/kuvaa_ei_saatavilla.jpg"

const Actions = ({ id, onSelect, isSelected }) => {
  return (
    <div onClick={() => onSelect(id)} className="absolute right-4">
      {isSelected ? (
        <div className="rounded-full bg-white inline-block align-bottom">
          <img src={IconCheckedSelected} width="40" className="block" />
        </div>
      ) : (
        <div className="rounded-full bg-white inline-block align-bottom">
          <img src={IconPlus} width="40" className="block" />
        </div>
      )}
    </div>
  )
}

export const defaultSearchParams = {
  productCategory: "",
  clientProfile: "",
  priceCategory: "",
}

const SearchProducts = () => {
  const { addItem } = useCart()

  const [selected, setSelected] = useState([])

  const location = useLocation()

  const searchParams = Object.assign(
    {},
    defaultSearchParams,
    parse(location.search)
  )

  const SEARCH_PRODUCTS = gql`
    query SEARCH_PRODUCTS($filters: ProductFiltersInput!) {
      products(
        filters: $filters
        sort: "sortOrder:asc"
        pagination: { limit: 100 }
      ) {
        data {
          id
          attributes {
            name
            country
            alcContents
            acidContents
            description
            aroma
            taste
            pairing
            grape
            packageSize
            price
            discountPrice
            sugarContents
            soldOnlyInGlass
            soldOnlyInPackage
            sortOrder
            priceCategory {
              data {
                attributes {
                  value
                }
              }
            }
            image {
              ...IMAGE_PARTS
            }
            productCategory {
              data {
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }

    ${IMAGE_PARTS}
  `

  let filters = {}

  if (searchParams.productCategory) {
    filters.productCategory = {
      slug: {
        eq: searchParams.productCategory,
      },
    }
  }
  if (searchParams.clientProfile) {
    filters.clientProfiles = {
      slug: {
        eq: searchParams.clientProfile,
      },
    }
  }
  if (searchParams.priceCategory) {
    filters.priceCategory = {
      slug: {
        eq: searchParams.priceCategory,
      },
    }
  }

  const { loading, data } = useQuery(SEARCH_PRODUCTS, {
    variables: {
      filters: filters,
    },
  })

  const getProductById = (id) => {
    return data.products.data.reduce((found, product) => {
      if (product.id === id) {
        found = product
      }
      return found
    })
  }

  const approveProducts = () => {
    selected.map((id) => {
      return addItem({
        ...getProductById(id),
        price: 0,
        manuallyAdded: true,
      })
    })
    navigate("/app/cart")
  }
  const backToProducts = () => {
    navigate("/app/search-form")
  }

  const handleSelect = (id) => {
    let updatedList = [...selected]
    if (!isSelected(id)) {
      updatedList = [...selected, id]
    } else {
      updatedList.splice(selected.indexOf(id), 1)
    }
    setSelected(updatedList)
  }

  const isSelected = (id) => {
    return selected.includes(id)
  }

  return (
    <PageContext.Provider value={{ pageType: "light" }}>
      <Layout>
        <div className="h-full grid place-items-center bg-white text-secondary pt-16">
          {loading || !data ? (
            <div className="loader-animation loader-animation__alternate"></div>
          ) : (
            <div className="p-8 grid place-items-center">
              <section className="my-6 pt-60">
                <h2 className="font-bold text-3xl text-center text-primary mb-6">
                  Lisää halutut tuotteet
                </h2>
                {data.products &&
                  data.products.data.map((product) => {
                    return (
                      <ProductCard
                        key={product.id}
                        product={product}
                        fallback={FallbackImage}
                        actions={
                          <Actions
                            id={product.id}
                            isSelected={isSelected(product.id)}
                            onSelect={() => handleSelect(product.id)}
                          />
                        }
                      />
                    )
                  })}
                {data.products.data.length === 0 && (
                  <p className="text-xl text-primary">
                    Ei tuotteita valituilla kriteereillä. Ole hyvä ja vaihda
                    ehtoja.
                  </p>
                )}
              </section>
              <section className="flex justify-center w-full p-9 space-x-4 sticky bottom-0 bg-white/75">
                <div
                  className="inline-block px-14 py-5 text-xl font-bold bg-primary text-white rounded-full relative pr-40"
                  onClick={() =>
                    data.products.data.length === 0
                      ? backToProducts()
                      : approveProducts()
                  }
                >
                  <span className="uppercase inline-block py-4">Ok</span>{" "}
                  <div className="inline-block align-bottom absolute right-3 top-[4px]">
                    <span className="koff-icon-fill-white-42">
                      <img
                        src={IconChecked}
                        width="40"
                        className="block relative z-10"
                      />
                    </span>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default SearchProducts
