import React, { useContext } from "react"
import { CartProvider } from "react-use-cart"

import { ApolloProvider } from "@apollo/client"
import { Redirect, Router } from "@reach/router"

import Cart from "../components/AppRoutes/Cart"
import Checkout from "../components/AppRoutes/Checkout"
import CustomerSelection from "../components/AppRoutes/CustomerSelection"
import ErrorOffline from "../components/AppRoutes/ErrorOffline"
import Login from "../components/AppRoutes/Login"
import OrderReceived from "../components/AppRoutes/OrderReceived"
import Profile from "../components/AppRoutes/Profile"
import SearchForm from "../components/AppRoutes/SearchForm"
import SearchProducts from "../components/AppRoutes/SearchProducts"
import SelectProfile from "../components/AppRoutes/SelectProfile"
import Layout from "../components/Layout"
import PrivateRoute from "../components/PrivateRoute"
import { apolloClient } from "../utils/auth"

const App = () => {
  return (
    <ApolloProvider client={apolloClient()}>
      <CartProvider>
        <Router>
          <PrivateRoute
            path="/app/customer-selection"
            component={CustomerSelection}
          />
          <PrivateRoute path="/app/select-profile" component={SelectProfile} />
          <PrivateRoute path="/app/profile" component={Profile} />
          <PrivateRoute path="/app/cart" component={Cart} />
          <PrivateRoute path="/app/checkout" component={Checkout} />
          <PrivateRoute path="/app/order-received" component={OrderReceived} />
          <PrivateRoute path="/app/search-form" component={SearchForm} />
          <PrivateRoute
            path="/app/search-products"
            component={SearchProducts}
          />
          <Login path="/app/login" />
          <ErrorOffline path="/app/offline" />
          <Redirect from="/app" to="/app/customer-selection" noThrow />
        </Router>
      </CartProvider>
    </ApolloProvider>
  )
}
export default App
