import React, { useState } from "react"
import NumberFormat from "react-number-format"

import IconChevronDown from "./../../icons/chevron-down-light.svg"
import IconChevronUp from "./../../icons/chevron-up-light.svg"

const WineInfo = ({
  product,
  buttonText,
  buttonCssClasses,
  buttonActiveCssClasses,
  iconCssClasses,
  onButtonClick,
  isShown,
}) => {
  return (
    <>
      <button
        onClick={() => onButtonClick()}
        className={isShown ? buttonActiveCssClasses : buttonCssClasses}
      >
        {buttonText}{" "}
        <img
          src={isShown ? IconChevronUp : IconChevronDown}
          className={iconCssClasses}
          alt={buttonText}
          width="16"
        />
      </button>
      {isShown && (
        <section className="order-2 w-full my-4 pt-10">
          <h3 className="text-primary font-bold">Aromi</h3>
          <p>{product.attributes.aroma}</p>
          <h3 className="text-primary font-bold">Maku</h3>
          <p>{product.attributes.taste}</p>
          <h3 className="text-primary font-bold">Ruokasuositus</h3>
          <p>{product.attributes.pairing}</p>
          <h3 className="text-primary font-bold">Rypäleet</h3>
          <p>{product.attributes.grape}</p>
          <div className="grid grid-cols-3 gap-2 max-w-md">
            <div>
              <h3 className="text-primary font-bold">Alkoholi</h3>
              <p>
                <NumberFormat
                  value={product.attributes.alcContents}
                  suffix="%"
                  isNumericString={true}
                  decimalSeparator=","
                  decimalScale={2}
                  displayType="text"
                  defaultValue=""
                />
              </p>
            </div>
            <div>
              <h3 className="text-primary font-bold">Sokeri</h3>
              <p>
                <NumberFormat
                  value={product.attributes.sugarContents}
                  suffix=" g/l"
                  isNumericString={true}
                  decimalSeparator=","
                  decimalScale={2}
                  displayType="text"
                  defaultValue=""
                />
              </p>
            </div>
            <div>
              <h3 className="text-primary font-bold">Happoja</h3>
              <p>
                <NumberFormat
                  value={product.attributes.acidContents}
                  suffix=" g/l"
                  isNumericString={true}
                  decimalSeparator=","
                  decimalScale={2}
                  displayType="text"
                  defaultValue=""
                />
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
export default WineInfo
